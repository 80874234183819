// Init sortable and handle keyboard events
import {toolboxMove} from './movers';
export function setupCustomSortableHandlers($toolbox) {

	//ui-sortable-disabled to track if we can sort
	//Handle 'trash' and 'plus' icons here too

	try {
		const shiftKey = 16;
		const altKey = 18;
		const leftArrowKey = 37;
		const rightArrowKey = 39;
		const deleteKey = 46;
		const enterKey = 13;
		const $btnAddRemoves = $toolbox.find('.btn-add-remove');
 
		var keypressStatus = {
			[shiftKey]: false,
			[altKey]: false,
			[leftArrowKey]: false,
			[rightArrowKey]: false
		};

		function isCustomising(){
			return $toolbox.hasClass('-customising');
		}

		//Set up events
		$toolbox.find('li').attr('tabindex', 0).bind('keydown', function (ev) {
			if (isCustomising()) {
				if (ev.which in keypressStatus) {
					keypressStatus[ev.which] = true;
				}
				if (keypressStatus[shiftKey] && keypressStatus[altKey] && keypressStatus[leftArrowKey]) {
					toolboxMove.left($(this));
				}
				if (keypressStatus[shiftKey] && keypressStatus[altKey] && keypressStatus[rightArrowKey]) {
					toolboxMove.right($(this));
				}
				if (ev.which === deleteKey || ev.which === enterKey) {
					toolboxMove.toggle($(this));
				}
			}

		}).bind('keyup', function(ev) {
			if (ev.which in keypressStatus) {
				keypressStatus[ev.which] = false;
			}
		});

		$btnAddRemoves.on('click',function(ev){
			if (isCustomising()) {
				toolboxMove.toggle($(this).parent('li'));
			}
		});
	}
	catch (err) {
		console.warn('There was a problem setting up keyboard navigation: ' + err);
	}
	
}